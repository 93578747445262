export function getCurrentEnvironment(currentDomain: string) {
  let currentEnvironment = '';

  switch (currentDomain) {
    case 'fjob-kyujin.com':
      currentEnvironment = 'production';
      break;
    case 'localhost':
      currentEnvironment = 'local';
      break;
    default:
      currentEnvironment = 'preview';
      break;
  }

  return currentEnvironment;
}